/*!
	 * @copyright &copy; Kartik Visweswaran, Krajee.com, 2013 - 2015
	 * @version 3.5.6
	 *
	 * A simple yet powerful JQuery star rating plugin that allows rendering
	 * fractional star ratings and supports Right to Left (RTL) input.
	 *
	 * For more JQuery/Bootstrap plugins and demos visit http://plugins.krajee.com
	 * For more Yii related demos visit http://demos.krajee.com
	 */
	/*
	 * Stars
	 */

	.rating-loading {
		width: 25px;
		height: 25px;
		font-size: 0px;
		color: #fff;
		background: transparent url('../../images/preloader.gif') top left no-repeat;
		border: none;
	}

	.fontawesome-icon { font-family: 'font-icons'; }
	.line-icon { font-family: 'lined-icons'; }
	.line2-icon { font-family: 'Simple-Line-Icons'; }

	.rating-gly-star .rating-stars:before { padding-left: 2px; }

	.rating-lg .rating-gly-star, .rating-lg .rating-gly-star .rating-stars:before { padding-left: 4px; }

	.rating-xl .rating-gly-star, .rating-xl .rating-gly-star .rating-stars:before { padding-left: 2px; }

	.rating-active { cursor: default; }

	.rating-disabled { cursor: not-allowed; }

	.rating-uni {
		font-size: 1.2em;
		margin-top: -5px;
	}

	.rating-container {
		position: relative;
		vertical-align: middle;
		display: inline-block;
		color: #EEE;
		overflow: hidden;
		padding-right: 2px;
	}

	.rating-container:before { content: attr(data-content); }

	.rating-container .rating-stars {
		position: absolute;
		left: 0;
		top: 0;
		white-space: nowrap;
		overflow: hidden;
		color: #fde16d;
		transition: all 0.2s ease-out;
		-o-transition: all 0.2s ease-out;
		-moz-transition: all 0.2s ease-out;
		-webkit-transition: all 0.2s ease-out;
	}

	.rating-container .rating-stars:before,
	.rating-container-rtl:before {
		content: attr(data-content);
		text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
	}

	.rating-container-rtl {
		position: relative;
		vertical-align: middle;
		display: inline-block;
		overflow: hidden;
		color: #fde16d;
	}

	.rating-container-rtl .rating-stars {
		position: absolute;
		left: 0;
		top: 0;
		white-space: nowrap;
		overflow: hidden;
		color: #EEE;
		transition: all 0.2s ease-out;
		-o-transition: all 0.2s ease-out;
		-moz-transition: all 0.2s ease-out;
		-webkit-transition: all 0.2s ease-out;
	}

	.rating-container-rtl .rating-stars:before { content: attr(data-content); }

	/**
	 * Rating sizes
	 */
	.rating-xl { font-size: 4em; }
	.rating-lg { font-size: 3em; }
	.rating-md { font-size: 2.5em; }
	.rating-sm { font-size: 2em; }
	.rating-xs { font-size: 1.5em; }

	/**
	 * Clear rating button
	 */
	.star-rating .clear-rating, .star-rating-rtl .clear-rating {
		color: #BBB;
		cursor: not-allowed;
		display: inline-block;
		vertical-align: middle;
		font-size: 50%;
	}

	.star-rating.rating-xl .clear-rating { padding: 15px 5px 0 ; }
	.star-rating.rating-lg .clear-rating,
	.star-rating.rating-md .clear-rating { padding: 10px 5px 0 ; }
	.star-rating.rating-sm .clear-rating { padding: 8px 5px 0 ; }
	.star-rating.rating-xs .clear-rating {  padding: 6px 5px 0 ;}

	.clear-rating-active { cursor: pointer !important; }

	.clear-rating-active:hover { color: #009943; }


	/**
	 * Caption
	 */
	.star-rating .caption, .star-rating-rtl .caption {
		color: #999;
		display: inline-block;
		vertical-align: middle;
		font-size: 55%;
	}

	.star-rating .caption { padding-left: 5px; }
	.star-rating-rtl .caption { padding-right: 5px; }

	.rtl .star-rating { direction: ltr; }

	.rtl .star-rating .clear-rating,
	.rtl .star-rating .rating-container,
	.rtl .star-rating .caption {
		display: block;
		float: right;
		padding-left: 0;
		margin: 8px 5px 8px 0;
	}

	.rtl .star-rating .rating-container { line-height: 1; }

	.rtl .star-rating .caption { margin-top: 10px; }

	/**
	 * Print
	 */
	@media print {
		.rating-container, .rating-container:before , .rating-container-rtl .rating-stars, .rating-container-rtl .rating-stars:before { color: #f3f3f3!important; }

		.star-rating .clear-rating, .star-rating-rtl .clear-rating { display: none; }
	}